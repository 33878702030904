<template>
    <div>
        <div v-if="$route.path === '/wuyiShare'" class="page" ref="page" >
            <div v-wechat-title="$route.meta.title"></div>
            <div class="Up">
               
                <div class="Main" @click="startApp">
                    
                    <img src="../static/image/indeximg/-s-Group-21@2x.png" class="big_img" ref="big_img" v-if="end">
                    <div class="big_end_info" v-else>
                        <img src="../static/image/indeximg/-s-Group-21@2x.png" class="big_img_end">
                        <div class="big_end_text">已结束</div>
                    </div>
                    
                </div>
                <div class="middle" @click="startApp">
                    <div class="prize_chance" ref="prize_chance">1次收获机会</div>
                    <div class="btn_group" >
                        <img src="../static/image/indeximg/Group 28.png" class="myAward" >
                        <div class="button" v-if="end" >
                            <img src="../static/image/indeximg/Layer 16.png" class="openBtn">
                            <div class="openBtnText" >收获麦子</div>
                        </div>
                        <div class="button" v-else>
                            <img src="../static/image/indeximg/Group 31.png" class="end_btn">
                        </div>
                        <img src="../static/image/indeximg/Group 26.png" class="getChange" is-link >
                    </div>
                </div>
            </div>
            <div class="rule" @click="$router.push('/wuyiRule')"></div>
            <div class="Down"></div>
            <img src="../static/image/share/share.png" class="download" @click="downLoad">
        </div>
        <div v-else>
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
import { Toast ,Dialog } from "vant";
import Vue from 'vue';
Vue.use(Dialog);
import utils from '../utils'


export default {
    name: "App",
    data() {
        return {
            userId:'',
            token:'',
            end:true,
            //已开奖次数
            exchanged:0,
            show_getChange:false,        
           
        };
    },
     created(){
         document.title='五一活动'
        this.status()
        this.timer2 = setInterval (this.showMarquee, 3000)
    },
    watch:{
        is_middle(val){
            if(val === true){
                this.$refs.timing2.src = this.timeingD
                this.$refs.timing1.src = this.timeingS
                this.$refs.left_img.src = this.stockingsS
                this.$refs.right_img.src = this.golden_elixirS
                this.$refs.big_img.src = this.redBagD
                this.openBtnText = '抢新年红包'
                this.carouselArr = carouselArr.hongbao
            }
        },
        is_newYear(val){
            if(val === true){
                this.$refs.timing2.src = this.timeingS
                this.$refs.timing1.src = this.timeingS
                this.$refs.timing3.src = this.timeingD
                this.$refs.left_img.src = this.stockingsS
                this.$refs.right_img.src = this.redBagS
                this.$refs.big_img.src = this.golden_elixirD
                this.openBtnText = '砸新年福蛋'
            }
        },
        end(val){
            if(val === false){
                console.log(this.$refs.prize_chance.innerText)
                this.$refs.prize_chance.innerText = ''
            }
        },
    },
    
    async mounted() {
        //获取userId和token
        this.userId = utils.getRequest().userId ? utils.getRequest().userId : "";
        this.token = utils.getRequest().token ? utils.getRequest().token : "";
       
        let {app_key, date, sign} = utils.getSign('v1/TwelveDay/getActivityInfo')
        // let {errNo,data,message} = await this.$api.getActivityInfo(app_key,date,sign,this.token,this.userId)
        let {errNo,data,message} = await this.$api.getActivityInfo(this.userId)
        if(errNo == 0){
            this.prize_chance = data.surplus_num
            this.end = data.end_time > new Date() ? false : true
        }
        

        window.SHARE_WEBHTML_MEISHE = ()=> {
            return this.SHARE_DATA;
        }
        
    },

    methods: {
        
        //轮播文字
        showMarquee: function () {
            this.animate = true;
            this.timer1 = setTimeout(() => {
                this.carouselArr.push(this.carouselArr[0]);
                this.carouselArr.shift();
                this.animate = false;
            }, 500);

        },
        //总状态定时器
        status(){
            this.timer = setInterval( ()=>{
                if(new Date() < this.red_time){
                    this.is_Christmas=true;
                    this.is_middle=false;
                    this.is_newYear=false;
                }else if(new Date() > this.red_time && new Date() < this.golden_elixir){
                    this.is_Christmas=false;
                    this.is_middle=true;
                    this.is_newYear=false;
                }else if(new Date() > this.golden_elixir ){
                    this.is_Christmas=false;
                    this.is_middle=false;
                    this.is_newYear=true;
                }else if(new Date() > this.golden_elixir_end){
                    this.is_Christmas=false;
                    this.is_middle=false;
                    this.is_newYear=false;
                }
            },0)
        },
        startApp(){
            Dialog.confirm({
                confirmButtonText:"启动",
                message: '参与该活动需启动云美摄APP'
            })
            .then(() => {
                utils.downLoad()
            })
        },
        downLoad(){
            utils.downLoad()
        }
    },
    beforeDestroy(){
        clearInterval(this.timer)
        clearInterval(this.timer2)
        clearTimeout(this.timer1)
    }
};
</script>

<style scoped >
img{
    display: block;
}
.page{
    position: relative;
    background: url('../static/image/indeximg/Shape 1076 copy@2x.png') no-repeat;
    background-size: 100%;
    height: 1053px;
}
.download{
    width: 100%;
    height: 59px;
    position: fixed;
    bottom: 0;
}
.progress_bar{
    width: 369px;
    height: 102px;
    margin-left: 6px;
}
.title_img{
    width: 347px;
    height: 105px;
    margin: 9px auto;
}
.carousel_text{
    position: absolute;
    top: 192px;
    left: 80px;
    width: 220px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
}
.carousel_item{
    display: flex;
    font-size: 10px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F5383C;
    justify-content: center;
}
.user_getInfo{
    margin: 0 7px 0 3px;
}
.rule{
    background: url('../static/image/indeximg/Group 20.png') no-repeat;
    background-size: 100%;
    width: 66px;
    height: 21px;
    position: absolute;
    top: 225px;
    right: -7px;
   
}
.Up{
    overflow: hidden;
}
.Main{
    display: flex;
    margin-top: 178px;
    justify-content: center;
}
.left_inner{
    margin-top: 83px;
}
.left_img{
    width: 77px;
    height: 76px;
}
.big_img{
    width: 207px;
    height: 206px;
    /* animation: rotate 1.5s infinite linear; */
}
.big_end_info{
    width: 207px;
    height: 206px;
}
.big_img_end{
    width: 100%;
    height: 100%;
}
.big_end_text{
    position: absolute;
    top: 422px;
    left: 153px;
    width: 70px;
    height: 24px;
    background: #FCF5DD;
    border: 1px solid #F9D4AE;
    border-radius: 8px;
    font-size: 13px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #E13431;
    line-height: 24px;
    text-align: center;
}
@keyframes rotate {
    
    0%{
        transform: rotate(0deg); 
    }
    25%{
        transform: rotate(10deg);
    }
    75%{
        transform: rotate(-10deg);
    }
    100%{
        transform: rotate(0);
    }
}
.right_inner{
    margin-top: 26px;
}
.right_img{
    width: 77px;
    height: 76px;
}
.open_status{
    width: 52px;
    height: 21px;
    margin: -5px auto 0;
}
.middle{
    position: relative;
    width: 100%;
    height: 96px;
    margin-top: 46px;
    padding: 7px 0;
    box-sizing: border-box;
}
.prize_chance{
    width: 75px;
    height: 12px;
    margin: 0 auto;
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    color: #F6D9B5;
    line-height: 15px;
    text-align: center;
}
.btn_group{
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myAward{
    width: 88px;
    height: 44px;
}
.openBtn{
    width: 163px;
    height: 57px;
    /* animation: scale 1.5s infinite linear; */
}
.end_btn{
    width: 163px;
    height: 57px;
}
@keyframes scale {
    0%{
        transform: scale(1);  /*开始为原始大小*/
    }
    
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}
.getChange{
    width: 88px;
    height: 44px;
}
.button{
    margin: 0 11px;
}
.openBtnText{
    position: absolute;
    top: 27px;
    left: 108px;
    width: 163px;
    height: 57px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 57px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.19);
    text-align: center;
    /* animation: scale 1.5s infinite linear; */
}
.Down{
    background: url('../static/image/indeximg/Layer 43@2x.png') no-repeat;
    height: 423px;
    width: 348px;
    margin: 50px auto;
    background-size: 100%;
}
.time_shaft{
    position: absolute;
    top: 64px;
    left: 64px;
    width: 273px;
    height: 5px;
    background: #F8A64C;
    box-shadow: 0px 2px 2px 0px rgba(248, 136, 14, 0.75);
    border-radius: 3px;
}
.timing1{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    left: 85px;
}
.timing2{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    left: 190px;
}
.timing3{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    right: 61px;
}
.timingD{
    position: absolute;
    top: 57px;
    width: 23px;
    height: 23px;
}
.float{
    height: 556px;
    width: 100%;
}
.float_main{
    background: url('../static/image/float/Group 31.png') no-repeat;
    background-size: 100%;
    height: 632px;
    position: relative;
}
.exchange_wrap{
    background: url('../static/image/float/Layer 26.png') no-repeat;
    background-size: 100%;
    height: 194px;
    position: absolute;
    top: 88px;
    width: 100%;
    display: flex;
    padding: 30px 41px 42px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}
.score{
    text-align: center;
}
.meimei{
    text-align: center;
}
.score_icon,.exchange_score_icon{
    width: 41px;
    height: 41px;
    margin: 0 auto 12px;
}
.exchange_score,.exchange_meimei,.exchange_success_score,.exchange_success_meimei,.meimei_no,.score_no,.score_no_time,.meimei_no_time{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.today_exchanged_score,.today_residue_score,.today_exchanged_meimei,.today_residue_meimei,.meimei_no_desc,.score_no_desc{
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
}
.success_exchanged_desc{
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
}
.exchange_sure_score,.know,.exchange_sure_meimei{
    width: 117px;
    height: 29px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    margin-top: 7px;
}
.meimei_icon,.exchange_meimei_icon{
    width: 48px;
    height: 35px;
    margin: 6px auto 12px;
}
.my_score,.exchange_time,.my_meimei{
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
    margin-bottom: 3px;
}
.exchange_btn{
    width: 117px;
    height: 27px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    margin: 0 auto;
}
.exchange_title{
    width: 156px;
    height: 18px;
    font-size: 19px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F6F2BD;
    line-height: 23px;
    text-align: center;
    position: absolute;
    top: 286px;
    left: 111px;
}
.mession_group{
    position: absolute;
    top: 317px;
    left: 17px;
}
.issue,.share,.invite{
    width: 343px;
    height: 86px;
    background: #FED8AB;
    border-radius: 10px;
    padding: 19px 8px 17px 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.issue_info,.share_info,.invite_info{
    margin: 0 12px 0 19px;
}
.issue_title,.share_title,.invite_title{
    width: 182px;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #CD2400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
}
.issue_desc1,.issue_desc2,.share_desc1,.share_desc2,.invite_desc1,.invite_desc2{
    /* width: 157px; */
    font-size: 13px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #D81400;
    line-height: 16px;
    text-align: start;
}
.issue_icon,.share_icon,.invite_icon{
    width: 30px;
    height: 30px;
}
.issue_btn,.share_btn,.invite_btn{
    width: 77px;
    height: 29px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 31px;  
    text-align: center;
}
.finished{
    width: 77px;
    height: 29px;
    background: #FFC3C3;
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #5C66A5;
    line-height: 29px;
    text-align: center;
}
.close{
    width: 24px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
}
.alert_master,.award_master{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
}
.alert_main{
    position: absolute;
    top: 130px;
    left: 35px;
    width: 300px;
    height: 343px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 23px ;
    box-sizing: border-box;
}
.award_main,.award_redBag{
    background: url('../static/image/alertimg/telecom_red_envelop.png') no-repeat;
    background-size: 100%;
    position: absolute;
    top: 107px;
    left: 52px;
    width: 270px;
    height: 325px;
}
.close_alert,.award_close{
    width: 32px;
    height: 32px;
    position: absolute;
    top: 489px;
    left: 169px;
}
.award_close{
    top: 458px;
    left: 170px;
}
.line{
    width: 189px;
    height: 14px;
    background: rgba(245, 87, 75, 0.3);
    margin: 58px auto 0;
}
.alert_title{
    width: 85px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202023;
    line-height: 22px;
    margin: -26px auto 0;
    text-align: center;
}
.red_time,.red_finishedTime,.lucky_time{
    width: 260px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F5574B;
    line-height: 22px;
    margin: 36px auto 0;
    text-align: center;
}
.red_finishedTime{
    margin: 73px auto 0;
}
.red_desc,.lucky_desc{
    width: 210px;
    height: 44px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F5574B;
    line-height: 22px;
    margin: 15px auto 33px;
    text-align: center;
}
.lucky_desc{
    width: 152px;
    margin-bottom: 73px;
}
.red_condition{
    width: 116px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555559;
    line-height: 17px;
    margin: 0 auto 23px;
}
.red_know,.lucky_know{
    width: 255px;
    height: 35px;
    background: #F5574B;
    border-radius: 18px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
}
.price_title,.price_name{
    width: 174px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F56857;
    line-height: 22px;
    margin: 31px auto 0;
    text-align: center;
}
.price_name{
    margin: 2px auto 0;
    text-align: center;
}
.price{
    width: 187px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F56857;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 22px auto 0;
    text-align: center;
}
.see_price,.goMoney{
    width: 202px;
    height: 41px;
    background: #FFDAA3;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F56857;
    line-height: 41px;
    margin: 65px auto 0;
    text-align: center;
}
.share_lucky{
    width: 201px;
    height: 39px;
    border-radius: 8px;
    border: 1px solid #FFDAA3;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFDAA3;
    line-height: 39px;
    margin: 22px auto 0;
    text-align: center;
}
.award_redBag_title{
    width: 174px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F56857;
    line-height: 22px;
    margin: 41px auto 36px;
    text-align: center;
}
.money_count{
    width: 187px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F56857;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 0 auto 97px;
    text-align: center;
}
.go_money{
    width: 202px;
    height: 41px;
    background: #FFDAA3;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F56857;
    line-height: 41px;
    margin: 0 auto;
    text-align: center;
}
.marquee_top {
    transition: all 0.5s;
    margin-top: -19px;
}
.sandian{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
